<template>
	<div class="grid">
		<Toast />
		<template v-if="loader==true">
		<div class="col-12">
			<!-- <center><i class="pi pi-spin pi-spinner" style="font-size: 3rem"></i></center> -->
			<ProgressBar mode="indeterminate" style="height: .5em" />
		</div>
		</template>
				<!-- <div class="col-4"> -->
				<div class="md:col-4 lg:col-4 xl:col-4 col-12">
					<div class="card height-100 flex flex-column align-items-center shadow-3">
						<!-- <img class="mt-3 cursor-pointer" src="layout/images/widgets/asset-profile.png" alt="profile" @click="openmodalmedia"> -->
						<img class="mt-3 cursor-pointer rounded-circle img-thumbnail" :src="img_profile" alt="profile" @click="openmodalmedia" width="130">
						<h5 class="mb-1">{{frmPerfil.name}}</h5>
							<div class="location flex align-items-center mb-2 mt-2">
								<template v-if="this.$store.getters['infoNavigation/IdRol'] == 1">
									<span class="person-tag indigo-bgcolor white-color p-1">Transporte Especial</span>
								</template>
								<template v-if="this.$store.getters['infoNavigation/IdRol'] == 2">
									<span class="person-tag teal-bgcolor white-color p-1">Generador</span>
								</template>
								<template v-if="this.$store.getters['infoNavigation/IdRol'] == 3">
									<span class="person-tag indigo-bgcolor white-color p-1">Proveedor</span>
								</template>
								<template v-if="this.$store.getters['infoNavigation/IdRol'] == 4">
									<span class="person-tag indigo-bgcolor white-color p-1">Tercero</span>
								</template>
								<template v-if="this.$store.getters['infoNavigation/IdRol'] == 5">
									<span class="person-tag bluegray-bgcolor white-color p-1">Empresa Transporte</span>
								</template>
							</div>
							
							<div class="location flex align-items-center mb-2 mt-2">
								<i class="pi pi-id-card mr-1"></i>
								<span>{{frmPerfil.document}}</span>
							</div>
							<div class="location flex align-items-center mb-2 mt-2">
								<i class="pi pi-phone mr-1"></i>
								<span>{{frmPerfil.cellphone}}</span>
							</div>
							<div class="location flex align-items-center mb-2 mt-2">
								<i class="pi pi-briefcase mr-1"></i>
								<span>{{frmPerfil.customer_job}}</span>
							</div>
					</div>
				</div>
				<!-- <div class="col-8"> -->
				<div class="md:col-8 lg:col-8 xl:col-8 col-12">
					<div class="surface-section shadow-3">
						<div class="font-medium text-3xl text-900 mb-3 pt-3 pl-3">Información del Perfil</div>
						<div class="text-500 mb-5 pl-3">Aquí encuentra su información de perfil actual.</div>
						<ul class="list-none p-0 m-0">

							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
								<div class="text-500 w-6 md:w-2 font-medium">Nombre</div>
								<template v-if="editarcargouser==true">
									<InputText class="text-900 w-full md:w-7 md:flex-order-0 flex-order-1" placeholder="Ingrese Nombre" v-model="frmPerfil.name" required="true" autofocus />
								</template>
								<template v-else>
									<div class="text-900 w-full md:w-7 md:flex-order-0 flex-order-1">{{frmPerfil.name}}</div>
								</template>
								<div class="w-6 md:w-3 flex justify-content-end">
								</div>
							</li>

							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
								<div class="text-500 w-6 md:w-2 font-medium">Email</div>
								<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
									{{frmPerfil.email}}
								</div>
								<div class="w-6 md:w-2 flex justify-content-end">
									<!-- <Button :disabled="true" label="Edit" icon="pi pi-pencil" class="p-button-text"></Button> -->
								</div>
							</li>

							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap"> 
								<div class="text-500 w-6 md:w-2 font-medium">Cédula</div>
								<template v-if="editarcargouser==true">
									<InputText class="text-900 w-full md:w-7 md:flex-order-0 flex-order-1" placeholder="Ingrese Cédula" v-model="frmPerfil.document" required="true" type="number" autofocus />
								</template>
								<template v-else>
									<div class="text-900 w-full md:w-7 md:flex-order-0 flex-order-1">{{frmPerfil.document}}</div>
								</template>
								<div class="w-6 md:w-3 flex justify-content-end">
									
								</div>
							</li>

							<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
								<div class="text-500 w-6 md:w-2 font-medium">Número de Celular</div>
								<template v-if="editarcargouser==true">
									<InputText class="text-900 w-full md:w-7 md:flex-order-0 flex-order-1" placeholder="Ingrese Celular" type="number" v-model="frmPerfil.cellphone" required="true" autofocus />
								</template>
								<template v-else>
									<div class="text-900 w-full md:w-7 md:flex-order-0 flex-order-1">{{frmPerfil.cellphone}}</div>
								</template>	
								<div class="w-6 md:w-3 flex justify-content-end">
									
								</div>
							</li>

							<li class="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 surface-border flex-wrap">
								<div class="text-500 w-6 md:w-2 font-medium">Cargo</div>
								<template v-if="editarcargouser==true">
									<InputText class="text-900 w-full md:w-7 md:flex-order-0 flex-order-1" placeholder="Ingrese Cargo" v-model="frmPerfil.customer_job" required="true" autofocus />
								</template>
								<template v-else>
									<div class="text-900 w-full md:w-7 md:flex-order-0 flex-order-1 line-height-3">
										{{frmPerfil.customer_job}}</div>
								</template>	
								<div class="w-6 md:w-3 flex justify-content-end">
									<template v-if="editarcargouser==false">
										<Button label="Editar" icon="pi pi-pencil" class="p-button-text" @click="editarcargo"></Button>
									</template>
									<template v-else>
										<Button label="Cancelar" icon="pi pi-ban" class="p-button-text" @click="editarcargouser=false"></Button>
										<Button label="Guardar" id="guardarinfo" icon="pi pi-save" class="p-button-text" @click="guardar_nombre"></Button>
									</template>
								</div>
							</li>
						</ul>
					</div>
				</div>
			<hr/>
			<template v-if="this.$store.getters['infoNavigation/IdRol'] == 2">
			<div class="md:col-4 lg:col-4 xl:col-4 col-12">
					
				</div>
				<div class="md:col-8 lg:col-8 xl:col-8 col-12 mt-3">
					<div class="surface-section shadow-3">
						<div class=" mr-1 mt-2 flex justify-content-end">
							<Button label="Crear nueva solicitud" icon="pi pi-plus" class="p-button-text mt-3" @click="openmodalmodalcustomer"></Button>
						</div>
						<div class="font-medium text-3xl text-900 mb-3 pt-1 pl-3">Empresas de Transporte</div>
						<div class="text-500 mb-5 pl-3">Aquí encuentra su información de las empresas de transporte<br> con estado y opciones.</div>
						<Accordion>
							<template v-for="(grup_empr,index) in grupos_empresariales_customers" :key="index">
							<AccordionTab>
								<template v-slot:header>
									<div class="flex align-items-center">
										<i class="pi pi-bookmark fs-xlarge mr-3 pink-color"></i>
										<div class="flex flex-column">
											<h5 class="mb-0">{{grup_empr.name}}</h5>
											<p>NIT: {{grup_empr.document}}</p>
										</div>
									</div>
								</template>
								<div class="mb-2 card">
									<div class=" formgrid grid  text-center flex">
											<div class=" col-10">
											<p class="link_tracking cursor-pointer" title="Copiar link" @click="copy_link_tracking(grup_empr.document)">{{url_front}}#/consulta-documento-cliente?customer={{grup_empr.document}} <i class="pi pi-link fs-xlarge blue-color ml-2 mb-2"></i></p>
											</div>
											<div class=" col-2">
										<Button icon="pi pi-eye" class="p-button-rounded p-button-success mr-2" title="Abrir en nueva pestaña"  @click="show_link_tracking(grup_empr.document)" />
										</div>
									</div>
								</div>
								
								<ul class="list-none p-0 m-0">
									<template v-for="(empresa,index_empresas) in grup_empr.empresas" :key="index_empresas">
									<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
										<div class="text-500 w-6 md:w-2 font-medium">Nombre</div>
											<div class="text-900 w-full md:w-7 md:flex-order-0 flex-order-1">{{empresa.razon_social}}</div>
											<template v-if="empresa.status == 0">
												<div class="w-6 md:w-3 flex justify-content-end"><Badge value="Pendiente" severity="warning" class="mr-2"></Badge></div>
											</template>
											<template v-if="empresa.status == 1">
												<div class="w-6 md:w-3 flex justify-content-end"><Badge value="Activo" severity="primary" class="mr-2"></Badge></div>
											</template>
											<template v-if="empresa.status == 2">
												<div class="w-6 md:w-3 flex justify-content-end"><Badge value="Bloqueado" severity="danger" class="mr-2"></Badge></div>
											</template>
										<div class="w-6 md:w-3 flex justify-content-end">
										</div>
									</li>
									</template>
								</ul>
							
							</AccordionTab>
							</template>
						</Accordion>
					</div>
				</div>
			</template>
			<Dialog v-model:visible="modalimg" :style="{width: '450px'}" header="Imagen de Perfil" :modal="true" class="p-fluid">
				<div class="flex align-items-center justify-content-center">
					<!-- <FileUpload mode="basic" :v-model="url_img" id="input-file-import" name="file_import" url="./upload.php" accept="image/*" :maxFileSize="1000000" ref="import_file" @change="onFileChange" /> -->
					<input type="file" class="form-control" :v-model="url_img" id="input-file-import" name="file_import" ref="import_file"  @change="onFileChange">
					
				</div>
				<div class="flex align-items-center justify-content-center mt-2">
						<template v-if="loader==true">
							<center><i class="pi pi-spin pi-spinner" style="font-size: 3rem"></i></center>
						</template>
					</div>
				<!-- <div class="flex align-items-center justify-content-center"> -->
					
				<!-- </div> -->
				<template #footer>
					<Button label="No" icon="pi pi-times" class="p-button-text" @click="modalimg = false"/>
					<Button id="buttonactualizarimg" label="Actualizar imagen" icon="pi pi-check" class="p-button-text" @click="actualizarImg" />
				</template>
			</Dialog>
			<Dialog v-model:visible="modalcrearparacustomer" :style="{width: '450px'}" header="Agregar empresa de transporte" :modal="true" class="p-fluid">
				<div class="field formgrid grid">
					<div class="col-12 my-3">
						<label for="name">NIT Empresa de Transporte</label>
						<InputMask mask="999.999.999" v-model="nit_customer" placeholder="NIT de la empresa transportadora*" :class="{'p-invalid': !nit_customer}"/>
					</div>
					<div class="col-12 my-3">
						<label for="name">NIT Empresa</label>
						<InputMask mask="999.999.999" v-model="nit_generador" placeholder="NIT de la empresa*" :class="{'p-invalid': !nit_generador}"/>
					</div>
				</div>
				<template v-if="loader==true">
					<center><i class="pi pi-spin pi-spinner" style="font-size: 3rem"></i></center>
				</template>
				<template #footer>
					<Button label="No" icon="pi pi-times" class="p-button-text" @click="modalcrearparacustomer = false"/>
					<Button id="agggenerador" label="Agregar" icon="pi pi-check" class="p-button-text" @click="agregar_customer" />
				</template>
			</Dialog>
	</div>
</template>

<script>
import axios from 'axios'
import { APIFRONT } from '../app.config'
import { APIENDPOINTIMG } from '../app.config'
import EventBus from '../event-bus';
// import { APIENDPOINTIMG } from '../../.env'

// axios.defaults.baseURL = APIENDPOINT;
export default {
	data() {
		return {
			message: [],
			customers: [],
			username:null,
			loader: false,
			url_front: APIFRONT,
			email:null,
			count: 0,
			ingresoCostumer: false,
			frmPerfil: [],
			grupos_empresariales_generadores: [],
			grupos_empresariales_customers: [],
			editarnombreuser: false,
			editarnumerouser: false,
			editarcargouser: false,
			nit_customer: '',
			nit_generador: '',
			editarcedulauser: false,
			modalimg: false,
			modalcrearparacustomer: false,
			url_img: '',
			import_file: '',
			img_profile: ''
		}
	},
	mounted(){
		this.cargar_empresas();
	},
	methods:{
		cargar_empresas(){
			this.loader = true;
			axios.get('/profile').then(response => {
				this.frmPerfil = response.data.data;
				// this.grupos_empresariales_generadores = response.data.generadores;
				this.grupos_empresariales_customers = response.data.customers;
				if(response.data.data.avatar==null){
					this.img_profile = APIENDPOINTIMG+'img_profiles/profile.jpg';
				}else{
					this.img_profile = APIENDPOINTIMG+'img_profiles/'+response.data.data.avatar;
					// document.cookie = "ImgUser="+APIENDPOINTIMG+'img_profiles/'+response.data.data.avatar;
					document.cookie = "ImgUser="+response.data.data.avatar;
					EventBus.emit('actualizarimgUser');
				}
				

				// console.log(this.frmPerfil);
				this.loader = false;
			}).catch(e => {
				console.log(e);
			});
		},
		show_link_tracking(nit){
			console.log("COPYY");
			window.open(APIFRONT+'#/consulta-documento-cliente?customer='+nit);
		},
		copy_link_tracking(nit){
			console.log("COPYY");
			navigator.clipboard.writeText(APIFRONT+'#/consulta-documento-cliente?customer='+nit);
			this.$toast.add({severity:'success', summary: 'Copiado Correctamente', detail:'El link ya se encuentra disponible para enviar!', life: 4000});
		},
		salirCustomer(){
			this.ingresoCostumer = false;
		},
		editarnombre(){
			this.editarnombreuser = true;
		},
		editarnumero(){
			this.editarnumerouser = true;
		},
		editarcargo(){
			this.editarcargouser = true;
		},
		editarcedula(){
			this.editarcedulauser = true;
		},
		openmodalmedia(){
			this.modalimg = true;
		},
		openmodalmodalcustomer(){
			this.modalcrearparacustomer = true;
		},
		agregar_customer(){
            // console.log(this.nit_customer.length);
			this.nit_customer = this.nit_customer.replaceAll('.', '');
			this.nit_generador = this.nit_generador.replaceAll('.', '');
			var _that = this;
			// console.log(this.nit_customer);
			// console.log(this.nit_customer.length);
			if (this.nit_customer.length == 0 || this.nit_generador.length == 0) {
				this.$toast.add({severity:'error', summary: 'NIT INCORRECTO', detail:'Favor ingresa un NIT valido', life: 4000});
			}else{
				// this.$toast.add({severity:'success', summary: 'NIT CORRECTO', detail:'La actualización de datos se realizo con exito!', life: 4000});
				this.loader = true;
				var agggenerador = document.getElementById('agggenerador');
				agggenerador.setAttribute("disabled", "");
				axios.post('/agregar_user_customer',{nit_customer: this.nit_customer,nit_generador: this.nit_generador}).then(response => {
					// document.cookie = "Nombre="+_that.frmPerfil.name;
					// _that.cargar_empresas();
					_that.$toast.add({severity:'success', summary: 'Actualizado Correctamente', detail:'La actualización de datos se realizo con exito!', life: 4000});
					agggenerador.removeAttribute('disabled');
					_that.modalcrearparacustomer = false;
					_that.loader = false;
					window.location.reload();
					// _that.editarnombreuser = false;
					// _that.editarcedulauser = false;
					// _that.editarnumerouser = false;
					// _that.editarcargouser = false;
					console.log(response);
				}).catch(function (error) {
                    if (error.response) {
						_that.loader = false;
						// console.log(error.response.data.message);
						agggenerador.removeAttribute('disabled');
						_that.$toast.add({severity:'error', summary: 'ERROR', detail:error.response.data.message, life: 4000});
					}
				});
			}
		},
		guardar_nombre(){
			this.loader = true;
			var _that = this;
			if(!this.frmPerfil.name || !this.frmPerfil.document || !this.frmPerfil.cellphone){
				this.$toast.add({severity:'error', summary: 'Falta información', detail:'Favor llenar todos los campos', life: 4000});
				this.loader = false;
			}else{
				var guardarinfo = document.getElementById('guardarinfo');
				guardarinfo.setAttribute("disabled", "");
				axios.post('/edit_profile',{frmPerfil: this.frmPerfil}).then(response => {
					document.cookie = "Nombre="+_that.frmPerfil.name;
					_that.cargar_empresas();
					_that.$toast.add({severity:'success', summary: 'Actualizado Correctamente', detail:'La actualización de datos se realizo con exito!', life: 4000});
					guardarinfo.removeAttribute('disabled');
					
					_that.editarnombreuser = false;
					_that.editarcedulauser = false;
					_that.editarnumerouser = false;
					_that.editarcargouser = false;
					console.log(response);
					window.location.reload();
					
				}).catch(e => {
					console.log(e);
					guardarinfo.removeAttribute('disabled');
				});
			}
			
		},
		onUpload(event) {
			// console.log(event);
			if(event.path[0].innerText!='Choose'){
				this.url_img = event.path[0].innerText;
			}
			
			this.$toast.add({severity: 'info', summary: 'Success', detail: 'File Uploaded', life: 3000});
		},
		onFileChange(e) {
            this.import_file = e.target.files[0];
            // console.log(this.import_file);
            // console.log(this.import_file.name);
        },
		actualizarImg(){
			if(!this.import_file){
				this.$toast.add({severity:'error', summary: 'Falta información', detail:'Favor llenar todos los campos', life: 4000});
			}else{
				var buttonactualizarimg = document.getElementById('buttonactualizarimg');
				buttonactualizarimg.setAttribute("disabled", "");
				this.loader = true;
				var _that = this;
				var formData = new FormData();
					formData.append('import_file', this.import_file);
					formData.append('id_user', this.frmPerfil.id);
					// console.log(formData);
					
					axios.post('/edit_profile_img',formData).then(response => {
						
						_that.img_profile = APIENDPOINTIMG+'img_profiles/'+response.data.nombre;
						document.cookie = "ImgUser="+APIENDPOINTIMG+'img_profiles/'+response.data.nombre;
						// document.cookie = "ImgUser="+APIENDPOINTIMG+'img_profiles/'+response.data.data.avatar;
						EventBus.emit('actualizarimgUser');
						_that.editarnombreuser = false;
						_that.editarcedulauser = false;
						_that.editarnumerouser = false;
						_that.editarcargouser = false;
						_that.modalimg = false;
						// console.log(response);
						_that.loader = false;
						_that.$toast.add({severity:'success', summary: 'Actualizado Correctamente', detail:'La actualización de datos se realizo con exito!', life: 4000});
						buttonactualizarimg.removeAttribute('disabled');
						window.location.reload();
					}).catch(e => {
						console.log(e);
						_that.loader = false;
						buttonactualizarimg.removeAttribute('disabled');
					});
			}
			
			// console.log(this.url_img);
		}
	}
}
</script>

<style scoped>
.link_tracking:hover{
	color: rgb(0, 132, 255);
	transition: 0.5s;
}
</style>
